<template>
  <div>
    <general-table
      ref="ticketsTable"
      api-url="support-tickets"
      add-type=""
      :view-content="false"
      :delete-content="false"
      :edit-content="false"
      type="modal"
      :columns="columns"
      :export-table="false"
      :search-box="false"
      :selectable="false"
      guard="friends"
    >
      <template #cell(created_at)="data">
        {{ timeFormat(data.item.created_at) }}
      </template>
      <template #cell(status)="data">
        <span :class="{ 'text-success' : Boolean(data.item.status) }">
          {{ Boolean(data.item.status) ? 'Done' : 'Pending' }}
        </span>
      </template>
      <template #actions="data">
        <b-button
          v-if="!Boolean(data.item.status)"
          size="sm"
          variant="primary"
          @click="openModal(data.item)"
        >
          Give Support
        </b-button>
      </template>
    </general-table>

    <div>
      <b-modal
        id="supportMessage"
        hide-footer
        centered
        title="Support message to the user"
      >

        <validation-observer
          ref="supportMessage"
          tag="form"
        >
          <!-- Message -->
          <validation-provider
            #default="validationContext"
            name="Message"
            rules="required|min:3"
          >
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Message"
                  label-for="message"
                >
                  <b-form-input
                    id="message"
                    v-model="form.text"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Type your Message"
                    @input="errors = {}"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <!-- Server Errors -->
                  <ul class="list-inline pt-0">
                    <li
                      v-for="(error, key, index) in errors"
                      :key="index"
                      class="text-danger small"
                    >
                      {{ error.toString() }}
                    </li>
                  </ul>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
              >
                <b-button
                  v-if="!loader"
                  variant="primary"
                  class="mr-1"
                  :disabled="!!validationContext.errors[0] || Object.values(errors).length > 0"
                  @click="sendMessage"
                >
                  Save
                </b-button>
                <b-button
                  v-if="loader"
                  variant="primary"
                  disabled
                  class="mr-1"
                >
                  <b-spinner
                    small
                    type="grow"
                  />
                  Loading...
                </b-button>
                <b-button
                  variant="outline-danger"
                  class="closeModal"
                  @click="closeModal"
                >
                  Cancel
                </b-button>
              </b-col>
            </b-row>
          </validation-provider>
        </validation-observer>
      </b-modal>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required, min } from '@validations'
import GeneralTable from '@/components/general-table/GeneralTable.vue'
import handleFormData from '@/global-compositions/handleFormData'

export default {
  components: { GeneralTable },
  data() {
    return {
      loader: false,
      required,
      min,
      columns: [
        { key: 'id' },
        { key: 'user_name', label: 'Owner Name' },
        { key: 'title', label: 'Title' },
        { key: 'body', label: 'Body' },
        { key: 'category', label: 'Category' },
        { key: 'status', label: 'Status' },
        { key: 'email', label: 'Email' },
        { key: 'phone', label: 'Phone' },
        { key: 'created_at', label: 'Created At' },
        { key: 'actions' },
      ],
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    const { formData, setFormData } = handleFormData()

    const form = ref({
      text: '',
      userId: '',
    })

    const errors = ref({})

    return {
      getValidationState,
      form,
      setFormData,
      formData,
      errors,
    }
  },
  methods: {
    timeFormat(date) {
      return moment(date).format(' DD MMM YYYY')
    },
    openModal({ id }) {
      this.form.userId = id
      this.$bvModal.show('supportMessage')
    },
    closeModal() {
      this.form = {
        text: '',
        userId: '',
      }
      this.$bvModal.hide('supportMessage')
    },
    sendMessage() {
      this.setFormData(this.form)
      this.formData.append('_method', 'POST')
      axios.post(`ticket/${this.form.userId}/changeStatus`, this.formData).then(res => {
        if (res.status === 200 || res.status === 201) {
          this.$refs.ticketsTable.getAllData()
          this.closeModal()
          this.$toasted.show('Message Sent Successfully', {
            position: 'top-center',
            duration: 3000,
          })
        }
      }).catch(error => {
        this.errors = error.response.data.errors
      })
    },
  },
}
</script>
